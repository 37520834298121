import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";
import { DndProvider } from "react-dnd";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

const Error = lazy(() => import("./pages/404"));

const LostAndFound = lazy(() => import("./pages/lostAndFound"));
const TouristHelp = lazy(() => import("./pages/touristHelp"));
const UserSupport = lazy(() => import("./pages/userSupportLayout"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box px={2}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="*" element={<Error />} />
                <Route
                  path="/help-center/lost-and-found"
                  element={<LostAndFound />}
                />
                <Route
                  path="/help-center/tourist-help"
                  element={<TouristHelp />}
                />
                <Route
                  path="/help-center/user-support"
                  element={<UserSupport />}
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </DndProvider>
      </Box>
    </ApolloProvider>
  );
}
